var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _vm._m(1),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("他のヘルプを見る")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" 主な流れ ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/account" },
                    },
                    [_vm._v(" 新規アカウント登録 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register" },
                    },
                    [_vm._v(" 作品登録(単体登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register-all" },
                    },
                    [_vm._v(" 作品登録(一括登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-list" },
                    },
                    [_vm._v(" 映画一覧の確認と編集 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/quotation" },
                    },
                    [_vm._v(" 見積書作成 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/upload" },
                    },
                    [_vm._v(" 納品アップロード ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/invoice" },
                    },
                    [_vm._v(" 請求書について ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/youtube-limit" },
                    },
                    [_vm._v(" Youtubeでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-register" },
                    },
                    [_vm._v(" Vimeoで動画登録を行いたいです ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-limit" },
                    },
                    [_vm._v(" Vimeoでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/password" },
                    },
                    [_vm._v(" アカウントのパスワードを忘れました ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/email" },
                    },
                    [_vm._v(" 新規アカウント登録完了メールが届きません ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("見積書作成"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-9 flex flex-col justify-center sm:col-span-1" },
      [
        _c(
          "div",
          {
            staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
            attrs: { id: "help-ch-07" },
          },
          [
            _c("div", { staticClass: "px-6 py-4" }, [
              _c("ul", { staticClass: "text-base" }, [
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _vm._v(
                      " VOD様より見積もり依頼がきた場合、コンテンツホルダー様は、"
                    ),
                    _c("br"),
                    _vm._v(
                      " フィルミネーションのプラットフォーム上で、見積書を作成することができます。 "
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("1.")]
                    ),
                    _vm._v(
                      " 登録したアカウントでログイン後、「見積もり一覧」を押してください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-01.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("2.")]
                    ),
                    _vm._v(
                      " 「見積もり一覧」に遷移しましたら、「交渉中」を押してください。「交渉中」タブに遷移します。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-02.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("3.")]
                    ),
                    _vm._v(
                      " 「交渉中」タブに遷移しましたら、今回見積もり依頼が来ている項目を押してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 見積もり依頼が来ているものは、状態が「見積書作成待ち」となっております。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-03.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("4.")]
                    ),
                    _vm._v(
                      " 見積書を作成するページが表示されます。まずは、左上の作品名を確認してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※同時に複数本の見積もりを行う場合には、下の方に同じように別の作品名が表示されます。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-04.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "my-4 ml-16" }, [
                  _c(
                    "p",
                    { staticClass: "flex flex-row font-bold text-red-600" },
                    [
                      _vm._v(
                        " 以下のページでは、見積もり作成ページの各項目について、ご説明いたします。"
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        " なお、購入者様の要望がない項目は、表示されませんので、"
                      ),
                      _c("br"),
                      _vm._v(
                        "この説明書にある全ての項目に対して確認することが必須ではありません。ご注意ください。 "
                      ),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("5.")]
                    ),
                    _vm._v(" 支払い方法の確認です。"),
                    _c("br"),
                    _vm._v(" こちらは、【固定金額でのお支払い】の場合です。"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " こちらのサンプルでは、海外の購入者様からの支払い総額が"
                    ),
                    _c("br"),
                    _vm._v(
                      " 「￥714,285」 その内、御社への支払いが 「￥500,000（税別）」ということになります。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" この内容で問題なければ「承認」を押してください。"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " ※承認を押した場合には、詳細内容が閉じて、左上、および右上の青い〇の表示がつきます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 再び詳細を確認したい場合には、バーの一番右の▽のボタンを押します。これはすべての項目で共通です。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-05.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-06.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-07.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-07-02.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("6.")]
                    ),
                    _vm._v(
                      " 前頁で「拒否」を押した場合には、カウンターオファーのためのポップアップが表示されます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ポップアップ内の入力枠に、希望金額を入力ください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " なお、そのすぐ下に、入力された金額の場合に御社が受け取れる金額が表示されます。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 希望金額の入力が終わりましたら、「カウンターオファーをする」ボタンを押してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " カウンターオファーをした場合、左上および右上に、赤い×マークが表示されます。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-08.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-09.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("7.")]
                    ),
                    _vm._v(" 支払い方法の確認です。"),
                    _c("br"),
                    _vm._v(
                      " こちらは【レベニューシェアでのお支払い】の場合です。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " こちらのサンプルでは、御社へのレベニューシェアの割合が35％となっています。"
                    ),
                    _c("br"),
                    _vm._v(
                      " （※購入者様からのレベニューシェアが50％で、そのうち御社の取り分が70％の場合、35％になります）"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " 期間中の想定売り上げ、およびその場合の御社への支払い額は、"
                    ),
                    _c("br"),
                    _vm._v(
                      "あくまで想定金額であり保証する内容ではありませんので、ご注意ください。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" この内容で問題なければ「承認」を押してください。"),
                    _c("br"),
                    _c("br"),
                    _vm._v(" ※承認を押した場合には、詳細内容が閉じて、"),
                    _c("br"),
                    _vm._v("左上、および右上の青い〇の表示がつきます。"),
                    _c("br"),
                    _vm._v(
                      " 再び詳細を確認したい場合には、バーの一番右の▽のボタンを押します。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-10.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-10-02.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("8.")]
                    ),
                    _vm._v(
                      " 前頁で「拒否」を押した場合には、カウンターオファーのためのポップアップが表示されます。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ポップアップ内の入力枠に、ご希望の最低保証金額を入力ください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " なお、そのすぐ下に、入力された金額の場合に御社が受け取れる金額が表示されます。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" 希望入力の入力が終わりましたら、"),
                    _c("br"),
                    _vm._v(
                      "「カウンターオファーをする」ボタンを押してください。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " カウンターオファーをした場合、左上および右上に、赤い×マークが表示されます。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-11.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-11-02.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("9.")]
                    ),
                    _vm._v(" 音声動画ファイルフォーマットの項目です。"),
                    _c("br"),
                    _vm._v(
                      " 画面には、購入者様が希望されているファイルフォーマットが表示されています。これに対し、御社がご用意可能ならば、「提供可能」にチェックを入れた上で、用意可能なフォーマットを選択してください。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" なお、本編、トレーラーともに同じ形式です。 "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-12.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-14.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("10.")]
                    ),
                    _vm._v(" 字幕の項目です。"),
                    _c("br"),
                    _vm._v(
                      " 画面には、購入者様が希望されている字幕、ファイル形式が表示されています。これに対し、御社がご用意可能ならば、「提供可能」にチェックを入れた上で、用意可能なフォーマットを選択してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " また、字幕を新規製作する場合の支払い負担希望についても、御社希望と合致するかご確認の上、選択してください。"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" なお、本編、トレーラーともに同じ形式です。 "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-15.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-17.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("11.")]
                    ),
                    _vm._v(" 配信エリアの項目です。"),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は、カナダ、メキシコ、アメリカでの配信を希望されているということになります。これに対し、御社がご許諾可能ならば、ご許諾可能なエリアと国をご選択ください。一方で、例えば、メキシコがNGの場合には、メキシコのチェックボックスを外してください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-18.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-19.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-20.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("12.")]
                    ),
                    _vm._v(" 権利の項目です。"),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は、SVODで非独占権を希望されているということになります。これに対し、御社がご許諾可能ならば、ご許諾可能な独占の種類と期間(※期間限定独占の場合)をご選択ください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-23.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-25.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("13.")]
                    ),
                    _vm._v(" チャンネルの項目です。"),
                    _c("br"),
                    _vm._v(
                      ' こちらの画面の場合、購入者様は、"Test"というチャンネルでの配信を希望されているということになります。これに対し、御社がご承諾されるならば、ご許諾可能なチャンネルをご選択ください。 '
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-26.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-28.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("14.")]
                    ),
                    _vm._v(" 購入者様が希望されるスケジュールの確認項目です。"),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は下記の納品日、配信/放送日を希望されているということになります。"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(" 納品希望日:2022年05月04日"),
                    _c("br"),
                    _vm._v(" 配信開始予定日:2022年05月11日"),
                    _c("br"),
                    _vm._v(" 配信開始遅延許容期間:3ヶ月まで"),
                    _c("br"),
                    _vm._v(" 配信終了予定日:2023年05月10日"),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(
                      " これに対し、御社がこのスケジュールをご承諾されるならば、特に変更は行わず、見積もりの有効期限のみ記入いただき、次の項目へとお進みください。一方で、スケジュールを変更されたい場合には、許諾可能な内容に変更ください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " 例えば、こちらの図で言えば、納品希望日を2022年06月04日に、配信開始予定日:2022年06月11日に、配信終了予定日:2023年06月10日に変更しております。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-29.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-31.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("15.")]
                    ),
                    _vm._v(" オフライン視聴の可否を確認する項目です。"),
                    _c("br"),
                    _vm._v(
                      " オフライン視聴というのは、SVODなどの契約ユーザーが、ネット環境のない場所で映画をみるために、自分の端末に作品をダウンロードできる機能で、Netflix、Amazonなどでも採用されており、VODサービスでは一般的な内容になります。"
                    ),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は、オフライン視聴を希望しています。これに対し、御社が許諾可能ならば、「承認する」をご選択ください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-35.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center font-semibold text-2xl",
                    },
                    [_vm._v("OR")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-37.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("16.")]
                    ),
                    _vm._v(" 提供する各種画像素材のスペック確認項目です。"),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は下記の各種画像を希望されているということになります。"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(" 場面写真:3枚をPNGで納品希望"),
                    _c("br"),
                    _vm._v(" ポスター画像:JPGで納品希望"),
                    _c("br"),
                    _vm._v(" ロゴ画像:AIで納品希望"),
                    _c("br"),
                    _vm._v(" キーアート:PSDで納品希望"),
                    _c("br"),
                    _vm._v(" メイキングのスチール写真:4枚をPDFで納品希望"),
                    _c("br"),
                    _vm._v(" ローレル:JPGで納品希望"),
                    _c("br"),
                    _vm._v(" 宣伝用のバナー:JPGで納品希望"),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(
                      " これに対し、御社がご用意可能ならば、提供可能にチェックが入っていることを確認の上、提供可能なフォーマットを選択ください。この際、併せて画像の「横幅」と「高さ」また「レイヤー分けされた画像を提供可能」かどうかも併せてご確認の上、枚数、ファイル形式、画像サイズ、レイヤー有無等を変更されたい場合には、提供可能な内容に変更ください。一方で、ご用意が難しい場合には、提供可能のチェックを外してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ※「レイヤー分けされた画像を提供可能」の項目は、購入者様の希望ファイル形式によっては、存在していない場合がございます。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-32.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("17.")]
                    ),
                    _vm._v(
                      " 提供する各種ドキュメント素材のスペック確認項目です。"
                    ),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は下記の各種画像を希望されているということになります。"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(
                      " 映画のクレジット　全てのクルーの名前（エンドロールの全て）：csvで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " 音楽クレジット　作中の全ての楽曲詳細（エンドロール分）： docで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " 所有権を示す資料（CHAIN OF TITLE）：pdfで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " 作品とその商標の法的権利のドキュメント：pdfで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " ミュージックキューシート、もしくは作中使用楽曲の使用タイミングをリスト化したものとその使用許諾書：docxで納品希望 ログライン「スクリプトをドラマチックに要約する説明文章」（英語で25単語以内のもの）：docxで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " キャッチフレーズ–映画を販売するためにポスターに掲載するように設計されたマーケティングコピー。（英語で4単語から6くらいまで）：xlsxで納品希望"
                    ),
                    _c("br"),
                    _vm._v(" あらすじ：docxで納品希望"),
                    _c("br"),
                    _vm._v(" 映画の売りになる説明文：txtで納品希望"),
                    _c("br"),
                    _vm._v(" 監督と主役の過去の実績：pdfで納品希望"),
                    _c("br"),
                    _vm._v(
                      " インタビューの書き起こしかPRに使える文章：pdfで納品希望"
                    ),
                    _c("br"),
                    _vm._v(
                      " タイトルが受賞した賞や上映されたフェスティバルの詳細情報：xlsで納品希望"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(
                      " これに対し、御社がご用意可能ならば、提供可能にチェックが入っていることを確認の上、提供可能なフォーマットを選択ください。 一方で、ご用意が難しい場合には、提供可能のチェックを外してください。"
                    ),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-38.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("18.")]
                    ),
                    _vm._v(" 提供するソーシャルメディアを確認する項目です。"),
                    _c("br"),
                    _vm._v(
                      " こちらの画面の場合、購入者様は下記の各種ソーシャルメディアを希望されているということになります。"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(" Facebook"),
                    _c("br"),
                    _vm._v(" Instagram"),
                    _c("br"),
                    _vm._v(" Twitter"),
                    _c("br"),
                    _vm._v(" website"),
                    _c("br"),
                    _vm._v(" Youtube"),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(
                      " これに対し、御社がご用意可能ならば、提供可能にチェックが入っていることを確認の上、提供可能なソーシャルメディアを選択ください。 一方で、ご用意が難しい場合には、提供可能のチェックを外してください。"
                    ),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-41.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("19.")]
                    ),
                    _vm._v(
                      " 納品方法を確認する項目です。納品方法には、以下の5種類が存在します。"
                    ),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                    _vm._v(" Filmination File Transfer (online)"),
                    _c("br"),
                    _vm._v(" Google Drive"),
                    _c("br"),
                    _vm._v(" Designated File Transfer Server"),
                    _c("br"),
                    _vm._v(" EMS (HDD)"),
                    _c("br"),
                    _vm._v(" FedEx (HDD)"),
                    _c("br"),
                    _vm._v(" ---------------------"),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-42.png"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("20.")]
                    ),
                    _vm._v(
                      " すべての確認が終わりましたら、ページ下の「この内容で見積もりを提出」ボタンを押してください。"
                    ),
                    _c("br"),
                    _vm._v(
                      " ボタンを押すと、「見積書を発行しました」というポップアップが表示されますので、「OK」を押してください。これで見積書の作成は完了です。見積もりが複数本ある場合には、上記を作品本数分、繰り返していただければと思います。ありがとうございました。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-43.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "flex flex-row items-center justify-center transform rotate-90 font-semibold text-2xl",
                    },
                    [_vm._v("→")]
                  ),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/07-44.jpg"),
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }