var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto" }, [
    _c("div", { staticClass: "w-full text-right mt-4" }, [
      _c("label", { staticClass: "mr-2", attrs: { for: "range" } }, [
        _vm._v("範囲"),
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.months,
              expression: "months",
            },
          ],
          staticClass: "h-10 bg-white border rounded-md border-gray-400",
          attrs: { id: "range" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.months = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function ($event) {
                return _vm.getLogs()
              },
            ],
          },
        },
        _vm._l([1, 3, 6], function (index) {
          return _c(
            "option",
            {
              key: index,
              domProps: { value: index, selected: index === _vm.months },
            },
            [_vm._v(_vm._s(index) + "ヶ月間")]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "grid grid-cols-3 gap-4 mt-4" }, [
      _c("div", { staticClass: "rounded-md border shadow p-2" }, [
        _c("p", [_vm._v("直近" + _vm._s(_vm.months) + "ヶ月のアクセス数")]),
        _c("p", { staticClass: "text-4xl font-bold text-center" }, [
          _vm._v(_vm._s(_vm.meta.total) + "件"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "rounded-md border shadow p-2" },
        [_c("FlMap", { attrs: { countries: _vm.countries } })],
        1
      ),
      _c("div", { staticClass: "rounded-md border shadow p-2" }),
    ]),
    _c(
      "div",
      { staticClass: "w-full rounded-md border shadow p-2 mt-4" },
      [
        _c("p", { staticClass: "text-sm text-gray-700" }, [
          _vm._v("アクセスグラフ"),
        ]),
        _c("D3LineChart", {
          attrs: { config: _vm.chartConfig, datum: _vm.chartData },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-full rounded-md border shadow mt-4 overflow-hidden" },
      [
        _c("table", { staticClass: "w-full" }, [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.logs, function (record) {
              return _c("tr", { key: record.id, staticClass: "border" }, [
                _c("td", { staticClass: "p-1" }, [
                  _vm._v(_vm._s(_vm._f("datetime")(record.created_at))),
                ]),
                _c("td", { staticClass: "p-1 underline" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/movies/detail/" + record.movie.uuid,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" " + _vm._s(record.movie.film_name) + " ")]
                  ),
                ]),
                _c("td", { staticClass: "p-1" }, [
                  _vm._v(
                    _vm._s(record.type === 1 ? "閲覧" : "スクリーナー視聴")
                  ),
                ]),
                _c("td", { staticClass: "p-1 underline" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/ch/vod_profile/" + record.viewedBy.uuid,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" " + _vm._s(record.viewedBy.company_name) + " ")]
                  ),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-gray-200" }, [
      _c("tr", { staticClass: "text-left" }, [
        _c("th", { staticClass: "p-1" }, [_vm._v("日時(日本時間)")]),
        _c("th", { staticClass: "p-1" }, [_vm._v("映画")]),
        _c("th", { staticClass: "p-1" }, [_vm._v("種類")]),
        _c("th", { staticClass: "p-1" }, [_vm._v("バイヤー")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }